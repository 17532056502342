<template>
  <div class="see">
    <div class="title">
      <span>报告详情</span>
    </div>
    <div class="line">
      <span>体检人信息</span>
    </div>
    <div class="content">
      <div class="key">
        <span>患者姓名：王爱飞</span>
      </div>
      <div class="key">
        <span>性别：男</span>
      </div>
      <div class="key">
        <span>年龄：24</span>
      </div>
      <div class="key">
        <span>证件号码：130730199800000000</span>
      </div>
      <div class="key">
        <span>套餐名称：男性体检基础A套餐</span>
      </div>
      <div class="key">
        <span>报告时间：2022-08-03  10：00：00</span>
      </div>
    </div>
    <div class="line">
      <span>报告详情</span>
    </div>
    <div class="content">
      <div class="left">
        <div class="head">
          <span>项目名称：常规名称</span>
          <span>项目编号：20900001</span>
        </div>
        <div class="table">
          <div class="table-head">
            <span>项目名称</span>
            <span>项目结果</span>
            <span>参考值</span>
          </div>
          <div class="tr">
            <span>身高</span>
            <span>175cm</span>
            <span></span>
          </div>
          <div class="tr">
            <span>体重</span>
            <span>65kg</span>
            <span></span>
          </div>
          <div class="tr">
            <span>BMI</span>
            <span>19.0</span>
            <span>18.5-22.9</span>
          </div>
          <div class="tr">
            <span>血压（舒张压）</span>
            <span>140mm/Hg</span>
            <span>90-140</span>
          </div>
          <div class="tr">
            <span>血压（舒张压）</span>
            <span>70mm/Hg</span>
            <span>60-90</span>
          </div>
        </div>
      </div>
      <div class="right">
        <div class="head">
          <span>项目名称：血常规三项</span>
          <span>项目编号：20900001</span>
        </div>
        <div class="table">
          <div class="table-head">
            <span>项目名称</span>
            <span>检验值</span>
            <span class="span">参考值</span>
          </div>
          <div class="tr">
            <span>血红蛋白</span>
            <span>300g/L</span>
            <span class="span">参考范围：110-150g/L</span>
          </div>
          <div class="tr">
            <span>红细胞</span>
            <span>300g/L</span>
            <span class="span">参考范围：110-150g/L</span>
          </div>
          <div class="tr">
            <span>血小板</span>
            <span>300g/L</span>
            <span class="span">参考范围：110-150g/L</span>
          </div>
          <div class="tr">
            <span>单核细胞绝对值</span>
            <span>300g/L</span>
            <span class="span">参考范围：110-150g/L</span>
          </div>
          <div class="tr">
            <span>淋巴细胞绝对值</span>
            <span>300g/L</span>
            <span class="span">参考范围：110-150g/L</span>
          </div>
          <div class="tr">
            <span>淋巴细胞百分比</span>
            <span>300g/L</span>
            <span class="span">参考范围：110-150g/L</span>
          </div>
          <div class="tr">
            <span>平均红细胞体积</span>
            <span>300g/L</span>
            <span class="span">参考范围：110-150g/L</span>
          </div>
        </div>
      </div>
    </div>
    <div class="btns">
      <el-button size="small" class="submit">保存</el-button>
      <el-button size="small" @click="close">取消</el-button>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    close () {
      this.$emit('close')
    }
  }
}
</script>

<style lang="less" scoped>
.see {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 99;
  width: 100%;
  height: 100%;
  padding: 60px;
  background-color: #081C38;
  box-sizing: border-box;

  .title {
    color: #01EEFD;
    font-size: 20px;
  }

  .line {
    width: calc(100% - 48px);
    height: 45px;
    line-height: 45px;
    margin: 0 auto;
    padding-left: 18px;
    border-bottom: 1px solid #01EEFD;
    box-sizing: border-box;
    color: #01EEFD;
    font-size: 18px;
    font-weight: bold;
  }

  .content {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    margin: 0 auto;
    margin-bottom: 10px;
    padding-left: 44px;
    box-sizing: border-box;

    .key {
      display: flex;
      align-items: center;
      width: 20%;
      margin-top: 23px;
      color: #01EEFD;
      font-size: 16px;
    }

    .left, .right {
      display: flex;
      flex-direction: column;
      width: 50%;

      .head {
        display: flex;
        align-items: center;
        height: 64px;
        color: #01EEFD;
        font-size: 16px;

        span {
          margin-right: 40px;
        }
      }

      .table {
        overflow: hidden;
        display: flex;
        flex-direction: column;
        width: 448px;
        border: 1px solid #01EEFD;
        border-radius: 6px;
        box-sizing: border-box;

        .table-head, .tr {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 100%;
          height: 45px;
          background-color: #01EEFD;

          span {
            flex: 1;
            text-align: center;
            white-space: nowrap;
            color: #FFFFFF;
            font-size: 17px;
          }
        }

        .tr {
          background-color: transparent;

          span {
            color: #01EEFD;
          }
        }
      }
    }

    .right {
      .table {
        width: 559px;
      }

      .span {
        flex: 2 !important;
      }
    }
  }

  .btns {
    display: flex;
    align-items: center;
    position: absolute;
    left: 60px;
    bottom: 60px;

    .el-button {
      margin-right: 50px;
      background-color: #33465C;
      border-radius: 0;
      border: none;
      color: #fff;
    }

    .submit {
      background: linear-gradient(107deg, #0176CC 0%, #025697 60%, #025697 60%, #024073 100%);
    }
  }
}
</style>
