<template>
  <div class="info">
    <div class="info-left">
      <UserBasicInfo></UserBasicInfo>
    </div>
    <div class="info-right">
      <div class="head">
        <div class="title" @click="switchFlan = !switchFlan">
          <span>视频问诊</span>
        </div>
      </div>
      <div class="content">
        <!-- 用户端 -->
        <User v-if="switchFlan"></User>
        <!-- 医生端 -->
        <Doctor v-else></Doctor>
      </div>
    </div>
  </div>
</template>

<script>
import UserBasicInfo from '../../../components/user-basic-info/user-basic-info.vue'
import User from './user.vue'
import Doctor from './doctor.vue'
export default {
  components: {
    UserBasicInfo,
    User,
    Doctor
  },
  data () {
    return {
      switchFlan: true
    }
  },
  methods: {}
}
</script>

<style scoped lang="less">
.info {
  display: flex;
  width: 100%;
  height: 100%;
  background-color: #071224;
  box-sizing: border-box;

  .info-left {
    width: 270px;
    height: 100%;
  }

  .info-right {
    position: relative;
    width: calc(100% - 280px);
    height: 100%;
    margin-left: 10px;
    padding: 20px;
    background-color: #081C38;
    box-sizing: border-box;

    .head {
      display: flex;
      align-items: center;
      height: 58px;
      padding: 0 18px;
      border-bottom: 1px solid #01EEFD;
      box-sizing: border-box;

      .title {
        width: 105px;
        height: 38px;
        line-height: 38px;
        text-align: center;
        background-color: #01EEFD;
        color: #fff;
        font-size: 22px;
      }
    }

    .content {
      display: flex;
      width: 100%;
      height: calc(100% - 90px);
      margin-top: 30px;
      padding: 30px;
      border: 4px solid rgba(1, 124, 219, .27);
      box-sizing: border-box;
    }
  }
}
</style>
