<template>
  <div class="see">
    <div class="title">
      <span>预约详情</span>
    </div>
    <div class="line">
      <span>体检人信息</span>
    </div>
    <div class="content">
      <div class="key">
        <span>患者姓名：王爱飞</span>
      </div>
      <div class="key">
        <span>就诊卡号：5000909000</span>
      </div>
      <div class="key">
        <span>证件类型：身份证号</span>
      </div>
      <div class="key">
        <span>证件号码：130730199800000000</span>
      </div>
      <div class="key">
        <span>患者头像：</span>
      </div>
      <div class="key">
        <span>微信昵称：zli</span>
      </div>
      <div class="key">
        <span>手机号码：15803339888</span>
      </div>
      <div class="key">
        <span>关系：本人</span>
      </div>
      <div class="key">
        <span>就诊人地址：石家庄市桥西区友谊街道</span>
      </div>
    </div>
    <div class="line">
      <span>预约信息</span>
    </div>
    <div class="content">
      <div class="key">
        <span>就诊医院：北京市第一人民医院</span>
      </div>
      <div class="key">
        <span>医院地址：北京市第一人民医院</span>
      </div>
      <div class="key">
        <span>证件类型：身份证号</span>
      </div>
      <div class="key">
        <span>报道科室：门诊三楼分诊台</span>
      </div>
      <div class="key">
        <span>预约体检时间：2022-08-03 周一上午</span>
      </div>
      <div class="key">
        <span>候诊时间：现场排号</span>
      </div>
      <div class="key">
        <span>体检套餐：男性体检基础A套餐</span>
      </div>
      <div class="key">
        <span>体检费：￥1200.00</span>
      </div>
      <div class="key">
        <span>预约时间：2022-08-03 10：08：08</span>
      </div>
      <div class="key">
        <span>状态：已完成</span>
      </div>
      <div class="date">
        <span>报告时间：2022-08-03 10：00：00</span>
        <el-button @click="openReport">查看报告</el-button>
      </div>
      <div class="date textarea">
        <span>预约备注：</span>
        <el-input type="textarea" placeholder="请输入内容">
        </el-input>
      </div>
    </div>
    <div class="btns">
      <el-button size="small" class="submit">保存</el-button>
      <el-button size="small" @click="close">取消</el-button>
    </div>
    <!-- 报告详情 -->
    <Report v-if="showReport" @close="closeReport"></Report>
  </div>
</template>

<script>
import Report from './report.vue'
export default {
  components: {
    Report
  },
  data () {
    return {
      showReport: false
    }
  },
  methods: {
    openReport () {
      this.showReport = true
    },
    closeReport () {
      this.showReport = false
    },
    close () {
      this.$emit('close')
    }
  }
}
</script>

<style lang="less" scoped>
.see {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 88;
  width: 100%;
  height: 100%;
  padding: 60px;
  background-color: #081C38;
  box-sizing: border-box;

  .title {
    color: #01EEFD;
    font-size: 20px;
  }

  .line {
    width: calc(100% - 48px);
    height: 45px;
    line-height: 45px;
    margin: 0 auto;
    padding-left: 18px;
    border-bottom: 1px solid #01EEFD;
    box-sizing: border-box;
    color: #01EEFD;
    font-size: 18px;
    font-weight: bold;
  }

  .content {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    margin: 0 auto;
    margin-bottom: 10px;
    padding-left: 44px;
    box-sizing: border-box;

    .key {
      display: flex;
      align-items: center;
      width: 20%;
      margin-top: 23px;
      color: #01EEFD;
      font-size: 16px;

      .el-button {
        background-color: #04B4D0;
        border-radius: 6px;
        border: none;
        color: #fff;
      }
    }

    .date {
      display: flex;
      align-items: center;
      width: 100%;
      margin-top: 23px;
      color: #01EEFD;
      font-size: 16px;

      .el-button {
        margin-left: 11px;
        background-color: #04B4D0;
        border-radius: 6px;
        border: none;
        color: #fff;
      }

      .el-textarea {
        width: 585px;
        height: 75px;
      }

      /deep/.el-textarea__inner {
        resize: none;
      }
    }

    .textarea {
      align-items: flex-start;
    }
  }

  .btns {
    display: flex;
    align-items: center;
    position: absolute;
    left: 60px;
    bottom: 60px;

    .el-button {
      margin-right: 50px;
      background-color: #33465C;
      border-radius: 0;
      border: none;
      color: #fff;
    }

    .submit {
      background: linear-gradient(107deg, #0176CC 0%, #025697 60%, #025697 60%, #024073 100%);
    }
  }
}
</style>
