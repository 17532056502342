<template>
  <div class="page">
    <div class="top">
      <div class="left">
        <div class="video">
          <div class="video-user"></div>
        </div>
        <img src="../../../assets/img/smartConsultation/hang-up.png" class="hang-up">
      </div>
      <div class="right">
        <div class="line">
          <span>张三</span>
          <span>男</span>
          <span>66岁</span>
          <span>古城莹镇卫生院</span>
        </div>
        <div class="line">
          <span>心率:72次/分钟</span>
        </div>
        <div class="line">
          <span>体温:36.5℃</span>
        </div>
        <div class="line">
          <span>血压:140mmHg</span>
        </div>
      </div>
    </div>
    <div class="bottom">
      <div class="left">
        <div class="title">清除记录</div>
      </div>
      <div class="right">
        <el-button size="small" class="submit">保存</el-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
    }
  }
}
</script>

<style lang="less" scoped>
.page {
  width: 100%;
  height: 100%;
  background-color: #fff;

  .top {
    display: flex;
    align-items: center;
    width: 100%;
    height: calc(100% - 176px);

    .left {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: calc(100% - 385px);
      height: 100%;
      padding: 31px;
      box-sizing: border-box;

      .video {
        position: relative;
        width: 100%;
        height: 313px;
        background-color: #333333;

        .video-user {
          position: absolute;
          top: 11px;
          right: 14px;
          width: 205px;
          height: 123px;
          background-color: #FFFFFF;
        }
      }

      .hang-up {
        width: 49px;
        height: 49px;
        margin-top: 13px;
      }
    }

    .right {
      width: 385px;
      height: 100%;
      padding: 20px;
      box-sizing: border-box;

      .line {
        display: flex;
        justify-content: space-between;
        margin-bottom: 16px;
        color: #ff0000;
        font-size: 16px;
      }

      .line:first-child {
        color: #333333;
      }
    }
  }

  .bottom {
    display: flex;
    width: 100%;
    height: 176px;
    border-top: 1px solid #E4E4E4;
    box-sizing: border-box;

    .left {
      width: calc(100% - 385px);
      height: 100%;
      padding: 21px 10px;
      box-sizing: border-box;

      .title {
        color: #333333;
        font-size: 18px;
      }
    }

    .right {
      display: flex;
      flex-direction: column-reverse;
      justify-content: flex-start;
      align-items: self-end;
      width: 385px;
      height: 100%;
      padding: 10px 30px;
      border-left: 1px solid #E4E4E4;
      box-sizing: border-box;

      .el-button {
        width: 108px;
        background-color: #409EFF;
        border: none;
        border-radius: 0;
        color: #fff;
      }
    }
  }
}
</style>
