<template>
  <div class="referral">
    <div class="head">
      <el-button size="small" :class="{ blue: tabIndex === 0, color: tabIndex === 1 }">预约体检</el-button>
      <el-input class="postion" placeholder="姓名/身份证号" suffix-icon="el-icon-search">
      </el-input>
    </div>
    <div class="input">
      <div class="item">
        <el-radio-group v-model="value">
          <el-radio label="1">全部</el-radio>
          <el-radio label="2">待体检</el-radio>
          <el-radio label="3">已完成</el-radio>
          <el-radio label="4">已退款</el-radio>
        </el-radio-group>
      </div>
      <div class="item">
        <span class="span">预约日期</span>
        <el-date-picker type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期">
        </el-date-picker>
      </div>
      <el-button class="blue" size="small">搜索</el-button>
    </div>
    <div class="swich">
      <el-table :data="tableData" height="400" max-height="400" :header-cell-style="{ background: '#024276' }">
        <el-table-column type="selection" width="50">
        </el-table-column>
        <el-table-column prop="name" label="姓名">
        </el-table-column>
        <el-table-column prop="date" label="就诊卡号">
        </el-table-column>
        <el-table-column prop="date" label="身份证号">
        </el-table-column>
        <el-table-column prop="type" label="手机号码">
        </el-table-column>
        <el-table-column prop="type1" label="套餐">
        </el-table-column>
        <el-table-column prop="type2" label="费用">
        </el-table-column>
        <el-table-column prop="type" label="状态">
        </el-table-column>
        <el-table-column prop="type" label="体检日期">
        </el-table-column>
        <el-table-column prop="type" label="预约时间">
        </el-table-column>
        <el-table-column label="操作" width="100">
          <!-- slot-scope="scope" -->
          <template>
            <div class="flex" @click="openSee">
              <span class="el-icon-s-order"></span>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination">
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="4" background
          :page-sizes="[100, 200, 300, 400]" :page-size="100" layout="total, prev, pager, next, jumper" :total="400">
        </el-pagination>
      </div>
    </div>
    <See v-if="showSee" @close="closeSee"></See>
  </div>
</template>

<script>
import See from './see.vue'
export default {
  components: {
    See
  },
  data () {
    return {
      tableData: [{
        date: '2020-02-27 17:20',
        name: '20200314001001',
        type: '普通会诊',
        type1: '在线会诊',
        type2: '北京朝阳医院',
        status: '1'
      }, {
        date: '2020-02-27 17:20',
        name: '20200314001001',
        type: '普通会诊',
        type1: '在线会诊',
        type2: '北京朝阳医院',
        status: '2'
      }, {
        date: '2020-02-27 17:20',
        name: '20200314001001',
        type: '普通会诊',
        type1: '在线会诊',
        type2: '北京朝阳医院',
        status: '3'
      }, {
        date: '2020-02-27 17:20',
        name: '20200314001001',
        type: '普通会诊',
        type1: '在线会诊',
        type2: '北京朝阳医院',
        status: '被驳回'
      }, {
        date: '2020-02-27 17:20',
        name: '20200314001001',
        type: '普通会诊',
        type1: '在线会诊',
        type2: '北京朝阳医院',
        status: '待审核'
      }, {
        date: '2020-02-27 17:20',
        name: '20200314001001',
        type: '普通会诊',
        type1: '在线会诊',
        type2: '北京朝阳医院',
        status: '待审核'
      }, {
        date: '2020-02-27 17:20',
        name: '20200314001001',
        type: '普通会诊',
        type1: '在线会诊',
        type2: '北京朝阳医院',
        status: '待审核'
      }],
      tabIndex: 0,
      options: [{
        value: '1',
        label: '1'
      }],
      value: '',
      showSee: false
    }
  },
  methods: {
    tabClick (index) {
      this.tabIndex = index
    },
    openSee () {
      this.showSee = true
    },
    closeSee () {
      this.showSee = false
    },
    handleSizeChange (val) {
      // console.log(`每页 ${val} 条`)
    },
    handleCurrentChange (val) {
      // console.log(`当前页: ${val}`)
    }
  }
}
</script>

<style lang="less" scoped>
.referral {
  position: relative;
  width: calc(100% - 10px);
  height: 100%;
  padding: 10px 37px;
  background: linear-gradient(114deg, rgba(10, 71, 123, .27) 0%, rgba(17, 94, 156, .27) 100%);
  // border: 4px solid rgba(1, 124, 219, .27);
  box-sizing: border-box;

  .swich {
    display: flex;
    flex-direction: column;
  }

  .head {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    height: 78px;
    border-bottom: 1px solid #01EEFD;
    box-sizing: border-box;

    .el-button {
      text-align: center;
      border-radius: 0;
      color: #fff;
      font-size: 22px;
      border: 0;
    }

    .blue {
      margin-right: 20px;
      background-color: #01EEFD;
    }

    .color {
      margin-right: 20px;
      background-color: #D5D5D5;
    }

    .width {
      width: 80px;
      font-size: 18px;
    }

    .el-input {
      width: 246px;
      height: 38px;
      border-radius: 6px;

      /deep/.el-input__inner {
        height: 38px;
        line-height: 38px;
      }
    }

    .postion {
      position: absolute;
      right: 0;
      background: linear-gradient(153deg, #0A477B 0%, #0E67AD 100%);
      border: 1px solid #1F72D3;

      /deep/.el-input__inner {
        background-color: transparent;
        color: #fff !important;
        font-size: 18px;
        border: none;

      }
    }

    /deep/ .el-input__suffix {
      color: #fff;
      font-size: 18px;
    }

    /deep/ .el-icon-search {
      display: flex;
      align-items: center;
    }
  }

  .input {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    padding-bottom: .125rem;
    border-bottom: 1px solid #01EEFD;

    .el-button {
      text-align: center;
      border-radius: 0;
      color: #fff;
      font-size: 22px;
      border: 0;
    }

    .el-select {
      width: 1.5rem;
    }

    .select {
      width: 2.575rem;
    }

    .blue {
      margin-right: .25rem;
      background-color: #01EEFD;
    }

    .search {
      width: 300px;
    }

    .item {
      display: flex;
      align-items: center;
      padding: .125rem 0;
      margin-right: .25rem;
      color: #01EEFD;
      font-size: .225rem;

      .el-radio {
        color: #fff;
      }

      /deep/.el-radio__label {
        font-size: .225rem;
      }

      /deep/.el-radio__input.is-checked+.el-radio__label {
        color: #01EEFD;
      }

      /deep/.el-radio__input.is-checked .el-radio__inner {
        background-color: #01EEFD;
        border-color: #01EEFD;
      }

      .span {
        margin-right: 10px;
      }

      .el-input {
        width: 206px;
        height: 38px;
        line-height: 38px;
        background-color: #fff;

        /deep/.el-input__inner {
          height: 38px;
          line-height: 38px;
        }
      }
    }
  }

  ::v-deep .el-table__body-wrapper {
    &::-webkit-scrollbar {
      width: 0px;
    }
  }

  ::v-deep .el-table th.gutter {
    display: none;
    width: 0;
  }

  ::v-deep .el-table colgroup col[name='gutter'] {
    display: none;
    width: 0;
  }

  ::v-deep .el-table__body {
    width: 100% !important;
  }

  .el-table {
    margin-top: 20px;
    background-color: #092B50;
    border: 4px #073F73 solid;
  }

  // 表头文字颜色
  /deep/.el-table__header-wrapper {
    thead {
      th {
        div {
          color: #01EEFD;
          font-size: 17px;
          font-weight: 400;
        }
      }
    }
  }

  //表格内容颜色
  /deep/.el-table__body tbody tr {
    color: #fff;
    background-color: #092B50;
  }

  /deep/.el-table--striped .el-table__body tr.el-table__row--striped td.el-table__cell {
    background-color: #092B50;
  }

  /deep/.el-table tbody tr:hover>td {
    background-color: transparent !important
  }

  // 改变边框颜色
  .el-table--border,
  .el-table--group {
    border: 2px solid #ddd !important;
  }

  /**
  改变表格内竖线颜色
   */
  .el-table--border td,
  .el-table--border th,
  .el-table__body-wrapper .el-table--border.is-scrolling-left~.el-table__fixed {
    border-right: 1px solid #ddd !important;
  }

  /deep/.el-table td {
    border-bottom: 1px solid #01EEFD;
  }

  /deep/.el-table thead tr th {
    border-color: transparent;
  }

  /deep/ .el-table--border::after,
  /deep/ .el-table--group::after,
  /deep/ .el-table::before {
    background-color: #073F73;
  }

  /deep/.el-table--border {
    border: 1px solid #073F73 !important;
  }

  /deep/.el-table .cell {
    text-align: center;
    color: #01EEFD;
  }

  /deep/.el-checkbox__inner {
    background-color: transparent;
  }

  .flex {
    width: 100%;
    color: #0e75d4;
    font-size: .3rem;

    span {
      cursor: pointer;
    }
  }

  .flex1 {
    justify-content: center;
  }

  .green {
    color: #00BB7E;
  }

  .yellow {
    color: #FF8014;
  }

  .red {
    color: #FF0000;
  }

  .purple {
    color: #073F73;
    cursor: pointer;
  }

  .gray {
    color: #D2D2D2;
    cursor: pointer;
  }

  .pagination {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: 40px;

    ::v-deep.el-pagination__total,
    ::v-deep.el-pagination__jump {
      color: #01EEFD;

      .el-input__inner {
        color: #fff;
        background-color: transparent;
      }
    }

    ::v-deep.el-pagination.is-background .el-pager li:not(.disabled).active {
      background-color: #01EEFD;
    }
  }
}
</style>
