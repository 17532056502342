<template>
  <div class="page">
    <div class="video">
      <div class="user"></div>
      <div class="doctor"></div>
      <div class="operate">
        <img class="sound" src="../../../assets/img/smartConsultation/sound.png">
        <img class="hang-up" src="../../../assets/img/smartConsultation/hang-up.png">
      </div>
    </div>
    <div class="news">
      <div class="doctor-info"></div>
      <div class="sort">
        <div class="item" v-for="(item, index) in list" :key="index">
          <img :src="item.url" class="img">
          <div class="sort-info">
            <span class="label">{{ item.label }}</span>
            <span>{{ item.value }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      list: Object.freeze([
        { label: '心率', url: require('../../../assets/img/smartConsultation/item0.png'), value: '75次/分' },
        { label: '血压', url: require('../../../assets/img/smartConsultation/item1.png'), value: '75/75mmHg' },
        { label: '血氧', url: require('../../../assets/img/smartConsultation/item2.png'), value: '96.2%SpO2' },
        { label: '血糖', url: require('../../../assets/img/smartConsultation/item3.png'), value: '8.1mmol/L' },
        { label: '体温', url: require('../../../assets/img/smartConsultation/item4.png'), value: '37℃' },
        { label: '尿糖', url: require('../../../assets/img/smartConsultation/item5.png'), value: '131mg/dl' },
        { label: '尿酸', url: require('../../../assets/img/smartConsultation/item6.png'), value: '210umol/L' },
        { label: '胆固醇', url: require('../../../assets/img/smartConsultation/item7.png'), value: '8.1mmol/L' },
        { label: '心电图', url: require('../../../assets/img/smartConsultation/item8.png'), value: '11-25 8:10' },
        { label: '尿常规', url: require('../../../assets/img/smartConsultation/item9.png'), value: '210umol/L' },
        { label: '血脂', url: require('../../../assets/img/smartConsultation/item10.png'), value: '8.1mmol/L' },
        { label: '更多检测', url: require('../../../assets/img/smartConsultation/item11.png'), value: '11-25 8:10' }
      ])
    }
  }
}
</script>

<style lang="less" scoped>
.page {
  display: flex;
  width: 100%;
  height: 100%;

  .video {
    width: 637px;
    height: 100%;

    .user,
    .doctor {
      width: 100%;
      height: 300px;
      background-color: #707070;
    }

    .doctor {
      margin-top: 10px;
      background-color: #333333;
    }

    .operate {
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      height: 60px;
      margin-top: 10px;

      .sound {
        width: 24px;
        height: 40px;
      }

      .hang-up {
        width: 40px;
        height: 40px;
      }
    }
  }

  .news {
    width: calc(100% - 655px);
    height: 100%;
    margin-left: 18px;

    .doctor-info {
      width: 100%;
      height: 300px;
      padding: 12px 20px;
      background-color: #fff;
      border-radius: 8px;
      box-sizing: border-box;
    }

    .sort {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      width: 100%;
      height: 340px;
      margin-top: 10px;

      .item {
        display: flex;
        align-items: center;
        width: 227px;
        height: 75px;
        padding: 20px;
        background-color: #fff;
        border-radius: 8px;
        box-sizing: border-box;

        .img {
          width: 41px;
          height: 41px;
        }

        .sort-info {
          display: flex;
          flex-direction: column;
          margin-left: 18px;
          color: #A1A6BB;
          font-size: 16px;

          .label {
            color: #2A374D;
          }
        }
      }
    }
  }
}
</style>
