var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"see"},[_vm._m(0),_vm._m(1),_vm._m(2),_vm._m(3),_c('div',{staticClass:"content"},[_vm._m(4),_vm._m(5),_vm._m(6),_vm._m(7),_vm._m(8),_vm._m(9),_vm._m(10),_vm._m(11),_vm._m(12),_vm._m(13),_c('div',{staticClass:"date"},[_c('span',[_vm._v("报告时间：2022-08-03 10：00：00")]),_c('el-button',{on:{"click":_vm.openReport}},[_vm._v("查看报告")])],1),_c('div',{staticClass:"date textarea"},[_c('span',[_vm._v("预约备注：")]),_c('el-input',{attrs:{"type":"textarea","placeholder":"请输入内容"}})],1)]),_c('div',{staticClass:"btns"},[_c('el-button',{staticClass:"submit",attrs:{"size":"small"}},[_vm._v("保存")]),_c('el-button',{attrs:{"size":"small"},on:{"click":_vm.close}},[_vm._v("取消")])],1),(_vm.showReport)?_c('Report',{on:{"close":_vm.closeReport}}):_vm._e()],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"title"},[_c('span',[_vm._v("预约详情")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"line"},[_c('span',[_vm._v("体检人信息")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content"},[_c('div',{staticClass:"key"},[_c('span',[_vm._v("患者姓名：王爱飞")])]),_c('div',{staticClass:"key"},[_c('span',[_vm._v("就诊卡号：5000909000")])]),_c('div',{staticClass:"key"},[_c('span',[_vm._v("证件类型：身份证号")])]),_c('div',{staticClass:"key"},[_c('span',[_vm._v("证件号码：130730199800000000")])]),_c('div',{staticClass:"key"},[_c('span',[_vm._v("患者头像：")])]),_c('div',{staticClass:"key"},[_c('span',[_vm._v("微信昵称：zli")])]),_c('div',{staticClass:"key"},[_c('span',[_vm._v("手机号码：15803339888")])]),_c('div',{staticClass:"key"},[_c('span',[_vm._v("关系：本人")])]),_c('div',{staticClass:"key"},[_c('span',[_vm._v("就诊人地址：石家庄市桥西区友谊街道")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"line"},[_c('span',[_vm._v("预约信息")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"key"},[_c('span',[_vm._v("就诊医院：北京市第一人民医院")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"key"},[_c('span',[_vm._v("医院地址：北京市第一人民医院")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"key"},[_c('span',[_vm._v("证件类型：身份证号")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"key"},[_c('span',[_vm._v("报道科室：门诊三楼分诊台")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"key"},[_c('span',[_vm._v("预约体检时间：2022-08-03 周一上午")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"key"},[_c('span',[_vm._v("候诊时间：现场排号")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"key"},[_c('span',[_vm._v("体检套餐：男性体检基础A套餐")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"key"},[_c('span',[_vm._v("体检费：￥1200.00")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"key"},[_c('span',[_vm._v("预约时间：2022-08-03 10：08：08")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"key"},[_c('span',[_vm._v("状态：已完成")])])
}]

export { render, staticRenderFns }